<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Status'"
      :label-width="labelWidth"
      :helper="helper"
      :error="error"
      :no-label="noLabel"
      :helper-position="helperPosition || 'lb'"
  >
    <erp-select
        @input="__set"
        :loading="loading"
        :placeholder="placeholder || 'Selecione'"
        :options="asyncdata"
        v-model="model"
        :multiple="multiple"
        :simpleBorder="simpleBorder"
        :size="size || '1'"
    ></erp-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import {tiposEmail as list} from '../../../../domain/pessoa/domain'

export default {
  name: 'ProdutividadeStatusEtapaSelect',
  mixins: [SelectMixin],
  props: {
  },
  data () {
    return {
      asyncdata: []
    }
  },
  computed: {},
  mounted () {
    this.load()
  },
  components: {},
  methods: {
    load () {
      //this.loading = true
      this.loading = false
      this.asyncdata = [
        {label: 'Pendente', value: 0},
        {label: 'Concluído', value: 1}
      ]
      /*list()
          .then((response) => {
            this.loading = false
            if (!Array.isArray(response.data.result)) {
              return
            }
            let list = response.data.result.map((obj) => {
              return {
                ...obj,
                label: obj.name,
                value: obj.id
              }
            })

            !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Selecione', value: null})
            this.asyncdata = list

            if (this.selectFirst) {
              this.$nextTick(() => {
                this.__emit(list[0].value)
              })
            }
          })
          .catch((error) => {
            this.loading = false

          })*/
    }
  }
}
</script>
