<template>
  <form @submit.prevent="save" class="w-m-content">
    <div class="w-m-header">
      <p class="font-14 no-margin no-select" style="color: #293e52">Modificar etapa <strong>{{ etapa.nome }}</strong>
      </p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1">
      <div style="min-height: 400px" class="font-11">
        <produtividade-status-etapa-select v-model="etapa.status"/>

        <canal-ciencia-status-select class="m-t" v-model="etapa.canalCiencia"/>

        <erp-s-field v-if="etapa.nome !== 'Indicação'" class="m-t" :label="etapa.nome === 'Nomeação' ? 'Data Despacho' : 'Data Protocolo'">
          <datetime-input input-class="app-input" date-type="date" date-format="##/##/####"
                          v-model.lazy="etapa.dataProtocolo"
                          simpleBorder
          />
        </erp-s-field>

        <erp-s-field class="m-t" label="Data Ciência">
          <datetime-input input-class="app-input" date-type="date" date-format="##/##/####"
                          v-model.lazy="etapa.dataCiencia"
                          simpleBorder
          />
        </erp-s-field>

        <erp-s-field class="m-t" :label="etapa.nome === 'Indicação' ? 'Data Indicação' : 'Data Manifestação Leiloeiro'">
          <datetime-input input-class="app-input" date-type="date" date-format="##/##/####"
                          v-model.lazy="etapa.dataManifestacao"
                          simpleBorder
          />
        </erp-s-field>

        <erp-s-field class="m-t" label="Resumo">
          <erp-input v-model="etapa.resumo"/>
        </erp-s-field>

        <erp-s-field class="m-t font-13" label="">
          <u-checkbox :value="true" v-model="fake.terms"/>
          Estou ciente deste registro
        </erp-s-field>

        <erp-s-field class="m-t e-input-modern size1" label="Descricao">
          <textarea class="full-width app-input input-textarea" rows="5" v-model="etapa.observacoes">
          </textarea>
        </erp-s-field>

      </div>
    </div>
    <div class="w-m-footer text-right footer-modern">
      <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps
             :color="'white'"
             text-color="grey-8"
             class="b-radius-3px btn-white m-r-sm"/>
      <u-btn :loading="loading" type="submit" label="Salvar" no-caps
             :color="'primary'"
             class="b-radius-3px"/>
    </div>
  </form>
</template>

<script>
import {ErpInput, ErpSField} from 'uloc-vue-plugin-erp'
import PersonMixin from "@/components/pessoa/components/include/PersonMixin"
import ProdutividadeStatusEtapaSelect from "@/components/processos/components/produtividade/StatusSelect"
import CanalCienciaStatusSelect from "@/components/processos/components/produtividade/CanalCienciaSelect"
import {date, UCheckbox} from "uloc-vue"
import {updateEtapa} from "@/domain/processos/services/produtividade"
import DatetimeInput from "@/reuse/input/Datetime"
import {datePtToEn} from '@/utils/date'

export default {
  name: 'ProdutividadeWindow',
  mixins: [PersonMixin],
  components: {
    DatetimeInput,
    CanalCienciaStatusSelect,
    ProdutividadeStatusEtapaSelect,
    ErpInput,
    ErpSField,
    UCheckbox
  },
  props: ['options', 'router', '_etapa'],
  data() {
    let etapa = null
    if (this._etapa) {
      etapa = JSON.parse(JSON.stringify(this._etapa))
      if (etapa.canalCiencia && etapa.canalCiencia.id) {
        etapa.canalCiencia = etapa.canalCiencia.id
      }
      if (etapa.dataCiencia) {
        etapa.dataCiencia = date.formatDate(etapa.dataCiencia.date, 'DD/MM/YYYY')
      }
      if (etapa.dataProtocolo) {
        etapa.dataProtocolo = date.formatDate(etapa.dataProtocolo.date, 'DD/MM/YYYY')
      }
      if (etapa.dataManifestacao) {
        etapa.dataManifestacao = date.formatDate(etapa.dataManifestacao.date, 'DD/MM/YYYY')
      }
    }
    return {
      loading: false,
      success: false,
      status: '',
      fake: {
        terms: false
      },
      etapa: etapa
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    save() {
      if (!this.fake.terms) {
        this.$uloc.dialog({
          title: 'Confirmação necessária.',
          message: 'Você não marcou estar ciente deste registro.'
        })
        return
      }
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.etapa))
      data.tipo = data.tipo.id
      if (data.dataCiencia) {
        data.dataCiencia = datePtToEn(data.dataCiencia)
      }
      if (data.dataProtocolo) {
        data.dataProtocolo = datePtToEn(data.dataProtocolo)
      }
      if (data.dataManifestacao) {
        data.dataManifestacao = datePtToEn(data.dataManifestacao)
      }
      updateEtapa(this.etapa.id, data)
          .then(response => {
            this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
            this.success = true
            this.dg()
          })
          .catch(error => {
            this.alertApiError(error)
          })
          .finally(() => {
            this.loading = false
          })
    }
  }
}
</script>
